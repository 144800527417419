import * as React from 'react'
import Layout from '../components/layout'
import ContactForm from '../components/contactForm'
import SkillsAccordion from '../components/skillsAccordion'
import {
  projectBody,
} from '../components/layout.module.scss'
import Seo from '../components/seo'
import shopifyLogo from '../assets/icons/stack/shopify-logo.svg';
import shopifyPlusLogo from '../assets/icons/stack/shopify-plus-logo.svg';
import gatsbyjsLogo from '../assets/icons/stack/gatsbyjs-logo.svg';
import metaBusinessPartnersLogo from '../assets/icons/stack/meta-business-partner-logo.svg';
import klaviyoLogo from '../assets/icons/stack/klaviyo-logo.svg';
import webinyLogo from '../assets/icons/stack/webiny-logo.svg';
import tailwindcssLogo from '../assets/icons/stack/tailwindcss-logo.svg';
import awsLogo from '../assets/icons/stack/amazon-web-services-white.svg';
import reactLogo from '../assets/icons/stack/react-logo-white.svg';
import reactNativeLogo from '../assets/icons/stack/reactnative-logo.svg';
import solidusLogo from '../assets/icons/stack/solidus-ecommerce-platform-white.svg';
import railsLogo from '../assets/icons/stack/ruby-on-rails-white.svg';

export default function AboutPage() {

  return (
    <Layout pageTitle="About">
    <Seo title="About" />
      <div className={projectBody}>
          <div className='grid grid-cols-1 lg:grid-cols-12 gap-14'>
            <div className='lg:col-span-8 pr-0 md:pr-5'>
            <h2 className="text-xl text-black font-semibold">Our Story</h2>
            <p className='mb-5 mt-3'>Established over two decades ago in the heart of Los Angeles, we have cultivated unparalleled expertise in elevating businesses' eCommerce presence. Our team has successfully navigated a diverse array of web design challenges across multiple sectors, from developing sophisticated data products to crafting dynamic eCommerce experiences that captivate and retain customers.</p>
            <br />
            <h2 className='text-xl text-black font-semibold'>Our Expertise</h2>
            <p className='mb-5 mt-3'>As a pioneering Creative Digital Agency, we specialize in cutting-edge GenAI services, innovative startups, luxury brands, fashion houses, lifestyle products, direct-to-consumer eCommerce, consumer goods, digital media, healthcare and wellness solutions, hospitality services, real estate ventures, financial institutions, and business services, among other sectors.</p>
            <p className='mb-5 mt-3'>Our team boasts extensive experience collaborating with a multitude of emerging brands on the Shopify platform. Our unparalleled design acumen and technical proficiency enable us to craft visually stunning and highly functional online shopping experiences for our customers, while simultaneously optimizing operational efficiencies for our clients.</p>
            <br />
            <h2 className='text-xl text-black font-semibold'>Our Approach</h2>
              <SkillsAccordion />

              {/* <h2 className="text-xl text-black font-semibold mb-5">Technologies we use everyday</h2>

              <div className="shadow-sm py-5 px-4 sm:p-4 lg:p-5 text-center mb-10 bg-bradient-blue">
              <div className='grid grid-cols-2 gap-4 sm:grid-cols-3 lg:grid-cols-4 lg:gap-3 py-5'>
                <div className=''>
                  <img
                      src={shopifyLogo}
                      alt="Shopify SVG Logo" 
                      title="Shopify"
                      className="mx-auto w-24 h-7 p-3 inline-block"
                    />
                </div>
                <div className=''>
                  <img
                      src={shopifyPlusLogo}
                      alt="Shopify Plus SVG Logo" 
                      title="Shopify Plus"
                      className="mx-auto w-32 h-7 py-3 block mb-2"
                      />
                </div>
                <div className=''>
                  <img
                      src={gatsbyjsLogo}
                      alt="Gatsby JS SVG Logo" 
                      title="GatsbyJS React-based open source framework"
                      className="mx-auto w-32 h-7 py-3 block mb-2"
                      />
                </div>
                <div className=''>
                  <img
                      src={metaBusinessPartnersLogo}
                      alt="Meta Business Partners SVG logo" 
                      title="Meta Business Partners"
                      className="mx-auto w-32 h-7 p-3 block mb-2"
                      />
                </div>
                <div className=''>
                  <img
                      src={klaviyoLogo}
                      alt="Klaviyo SVG Logo" 
                      title="Klaviyo Marketing Platform"
                      className="mx-auto w-32 h-7 p-3 block mb-2"
                      />
                </div>
                <div className=''>
                  <img
                      src={webinyLogo}
                      alt="Webiny SVG Logo" 
                      title="Webiny Open-Source Serverless CMS"
                      className="w-32 h-7 p-3 block mb-2"
                      />
                </div>
                <div className=''>
                  <img
                      src={tailwindcssLogo}
                      alt="TailwindCSS SVG logo" 
                      title="TailwindCSS Services"
                      className="mx-auto w-32 h-7 p-3 block mb-2"
                      />
                </div>
                <div className=''>
                  <img
                      src={awsLogo}
                      alt="Amazon Web Services AWS SVG Logo" 
                      title="Amazon Web Services (AWS)"
                      className="mx-auto w-24 h-9 p-3 block mb-2"
                      />
                </div>
                <div className=''>
                  <img
                      src={reactLogo}
                      alt="React SVG Logo" 
                      title="React Stack Development"
                      className="mx-auto w-24 h-8 p-3 block mb-2"
                      />
                </div>
                <div className=''>
                  <img
                      src={reactNativeLogo}
                      alt="React Native SVG Logo" 
                      title="React Native Stack Development"
                      className="mx-auto w-28 h-7 p-3 block mb-2"
                      />
                </div>
                <div className=''>
                  <img
                      src={solidusLogo}
                      alt="Solidus Rails Ecommerce Platform SVG Logo" 
                      title="Solidus: Rails Ecommerce Platform"
                      className="mx-auto w-20 h-7 p-3 block mb-2"
                      />
                </div>
                <div className=''>
                  <img
                      src={railsLogo}
                      alt="Ruby on Rails SVG Logo" 
                      title="Ruby on Rails Stack Development"
                      className="mx-auto w-20 h-7 p-3 block mb-2"
                      />
                </div>
              </div>

              </div> */}
              
              <h2 className='text-xl text-black font-semibold'>Our Team's Expertise</h2>
              <p className='mb-5 mt-3'>Our accomplished team brings a wealth of industry-specific experience, with particular proficiency in Entertainment, Startups, Luxury Goods, Fashion, Lifestyle Brands, Direct-to-Consumer eCommerce, and Retail sectors. Their collective expertise has been instrumental in the conceptualization, design, and successful launch of numerous high-profile celebrity applications, including those for renowned figures such as Ellen DeGeneres, Kim Kardashian, Kylie Jenner, Kevin Hart, and prominent Social Media Influencers. Furthermore, our team has collaborated with leading entertainment companies, including Warner Bros, WarnerMedia, AT&T, and other industry giants, solidifying our reputation for delivering exceptional digital solutions across diverse platforms.</p>
              <br />
            </div>
            <div className='lg:col-span-4'>
              <div className="float-right sticky top-24 inline-block">
                <ContactForm />
              </div>
            </div>
        </div>
      </div>
    </Layout>
  )
}